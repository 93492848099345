<template>
  <div>
    <b-button
      v-b-modal.modal-item-type
      class="green-button-transparent mr-3 border-0"
      data-dashboard-step="3"
    >
      <b-icon
        icon="plus-circle-fill"
        class="mr-2 top--3"
        aria-hidden="true"
        scale="1"
      ></b-icon>
      {{ $t('webmag.item_add') }}
    </b-button>

    <b-modal
      id="modal-item-type"
      size="lg"
      centered
      no-stacking
    >
      <template #modal-header>
        <h3>{{ $t('webmag.item_add') }}</h3>
      </template>
      <template #default>
        <div class="row">
          <div
            v-if="$hasPrivileges(
              $privileges('CAN_CREATE_PROJECTS'),
              isSysAdmin,
              parentItem.privilegesForItem,
            )"
            class="border-right-light-grey-md"
            :class="($hasPrivileges
              ($privileges('CAN_CREATE_PAGES'), isSysAdmin, parentItem.privilegesForItem)
              ) ? 'col-md-6' : 'col-md-12'
            ">
            <b-button
              v-b-modal.modal-component-project
              class="mx-auto mb-4 d-block light-grey-button"
              @click="setItem('project')"
            >
              <b-icon
                icon="plus-circle-fill"
                class="mr-2"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('modals.createItem.chooseItemType.projectButton') }}
            </b-button>
            <p v-html="$t('modals.createItem.chooseItemType.projectButtonDesc')"></p>
          </div>
          <div
            v-if="$hasPrivileges(
              $privileges('CAN_CREATE_PAGES'),
              isSysAdmin,
              parentItem.privilegesForItem,
            )"
            class="select-page col-md-6"
            :class="($hasPrivileges(
              $privileges('CAN_CREATE_PROJECTS'), isSysAdmin, parentItem.privilegesForItem))
              ? 'col-md-6' : 'col-md-12'
            ">
            <b-button
              v-b-modal.modal-component-page
              class="mx-auto mb-4 d-block light-grey-button"
              @click="setItem('page')"
            >
              <b-icon
                icon="plus-circle-fill"
                class="mr-2"
                aria-hidden="true"
                scale="1"
              ></b-icon>
              {{ $t('modals.createItem.chooseItemType.pageButton') }}
            </b-button>
            <p v-html="$t('modals.createItem.chooseItemType.pageButtonDesc')"></p>
          </div>
        </div>
      </template>
      <template #modal-footer="{ close }">
        <b-button
          @click="close()"
          block
          class="p-3"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </template>
    </b-modal>
    <modal-component-project
      v-if="$hasPrivileges(
        $privileges('CAN_CREATE_PROJECTS'),
        isSysAdmin,
        parentItem.privilegesForItem,
      )"
      :item-position="folderProjectPosition"
      :child-slugs="childSlugs"
      :parent-id="parentItem.id"
      :parent-item="parentItem"
      :group-id="groupId"
      :group-slug="groupSlug"
      :group-domain="groupDomain"
      :group-path="groupPath"
      :email="email"
      @insert-data="insertNewData"
    ></modal-component-project>
    <modal-component-page
      v-if="$hasPrivileges(
        $privileges('CAN_CREATE_PAGES'),
        isSysAdmin,
        parentItem.privilegesForItem,
      )"
      :parent-item="parentItem"
      :item-position="pagePosition"
      :child-slugs="childSlugs"
      :group-id="groupId"
      :group-path="groupPath"
      :group-domain="groupDomain"
      :group-slug="groupSlug"
      :email="email"
    ></modal-component-page>
  </div>
</template>

<script>
export default {
  name: 'ModalChooseItemType',
  components: {
    ModalComponentPage: () => import('@/components/modals/ModalComponentPage.vue'),
    ModalComponentProject: () => import('@/components/modals/ModalComponentProject.vue'),
  },
  props: {
    parentId: {
      type: Number,
      default: null,
    },
    childSlugs: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      required: false,
    },
    pagePosition: {
      type: Number,
      required: true,
    },
    folderProjectPosition: {
      type: Number,
      required: true,
    },
    parentItem: {
      type: Object,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
    groupSlug: {
      type: String,
    },
    groupPath: {
      type: String,
    },
    groupDomain: {
      type: String,
    },
    email: {
      type: String,
    },
  },
  data: () => ({
    typeOfNewItem: null,
  }),
  computed: {
    typeOfItem() {
      return this.typeOfNewItem;
    },
  },
  methods: {
    insertNewData() {
      this.$emit('insert-data');
    },
    setItem(itemType) {
      this.typeOfNewItem = itemType;
    },
  },
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
}
</style>
